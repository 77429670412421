import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Confetti from 'react-confetti'
import LOCDialog from '../dialog/LOCDialog'
import SkillDialog from '../dialog/SkillDialog'
import PlayerHeadshotAndName from '../PlayerHeadshotAndName'
import ScoutSkillLOCProgress from './ScoutSkillLOCProgress'
import { useGetWeeklySkillLOCPlayers, useWatchVariable } from '@/lib/hooks'

const ScoutViewSkeleton = (): JSX.Element => (
    <Box>
        {Array.from({ length: 5 }, (_, i) => i).map((d) => (
            <Box
                key={d}
                sx={{
                    display: 'flex',
                    paddingX: 1,
                    paddingY: 1.5,
                    justifyContent: 'space-between',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Box sx={{ display: 'flex', width: '60%', gap: 1 }}>
                    <Skeleton width="20px" height={15} variant="circular" />
                    <Skeleton width="60%" height={15} variant="rounded" />
                </Box>
                <Box sx={{ display: 'flex', width: '40%', gap: 1, justifyContent: 'flex-end' }}>
                    <Skeleton width="40%" height={15} variant="rounded" />
                    <Skeleton width="40%" height={15} variant="rounded" />
                </Box>
            </Box>
        ))}
    </Box>
)

type SkillLOCWidgetScoutViewProps = {
    scoutEntityId: string | undefined
}
const SkillLOCWidgetScoutView = ({ scoutEntityId }: SkillLOCWidgetScoutViewProps): JSX.Element => {
    const [addPlayerLOC, setAddPlayerLOC] = useState<string | undefined>(undefined)
    const [addPlayerSkill, setAddPlayerSkill] = useState<string | undefined>(undefined)
    const [isExploding, setIsExploding] = useState<boolean>(false)
    const { data: players, isLoading } = useGetWeeklySkillLOCPlayers(scoutEntityId)
    const filteredPlayers = players?.filter((p) => !p.locsCompletedThisWeek || !p.skillsCompletedThisWeek) || []
    const containerWidth = document.getElementById('scout-container')?.offsetWidth || 0

    useWatchVariable(() => {
        if (filteredPlayers.length === 0) {
            setIsExploding(true)
        }
    }, [filteredPlayers.length])

    return (
        <>
            <Box id="scout-container" sx={{ paddingBottom: '25px' }}>
                {isExploding && (
                    <Confetti
                        gravity={0.05}
                        width={containerWidth}
                        height={400}
                        initialVelocityX={2}
                        initialVelocityY={2}
                        numberOfPieces={100}
                        recycle={false}
                        onConfettiComplete={() => {
                            setIsExploding(false)
                        }}
                    />
                )}

                {isLoading && <ScoutViewSkeleton />}
                {!isLoading && !filteredPlayers.length && (
                    <Typography
                        variant="h6"
                        color="text.secondary"
                        sx={{ width: '100%', textAlign: 'center', marginTop: 3 }}
                    >
                        Weekly Players Complete
                    </Typography>
                )}
                {filteredPlayers.map((player) => (
                    <Box
                        key={player.playerId}
                        sx={{
                            display: 'flex',
                            gap: 1,
                            paddingX: 1,
                            paddingY: 0.5,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            fontSize: '14px',
                            borderTop: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        <Box sx={{ width: '65%' }}>
                            <PlayerHeadshotAndName
                                playerUrlSlug={player.urlSlug}
                                name={player.fullName}
                                lastName={player.lastName}
                                imageUrl={player.headshotUrl}
                                imageHeight={30}
                                imageWidth={30}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, width: '35%' }}>
                            <Box sx={{ whiteSpace: 'nowrap', width: '50%' }}>
                                {!player.skillsCompletedThisWeek && (
                                    <Button
                                        sx={{ padding: 0.5 }}
                                        variant="text"
                                        onClick={() => setAddPlayerSkill(player.playerId)}
                                    >
                                        + SKILLS
                                    </Button>
                                )}
                            </Box>
                            <Box sx={{ whiteSpace: 'nowrap', width: '50%' }}>
                                {!player.locsCompletedThisWeek && (
                                    <Button
                                        sx={{ padding: 0.5 }}
                                        variant="text"
                                        onClick={() => setAddPlayerLOC(player.playerId)}
                                    >
                                        + LOCS
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Box>
                ))}
            </Box>
            {!!addPlayerLOC && (
                <LOCDialog
                    open
                    setOpen={setAddPlayerLOC}
                    name={players?.find((p) => p.playerId === addPlayerLOC)?.fullName || ''}
                    playerId={addPlayerLOC}
                    isNBAPlayer
                />
            )}
            {!!addPlayerSkill && (
                <SkillDialog
                    open
                    setOpen={setAddPlayerSkill}
                    name={players?.find((p) => p.playerId === addPlayerSkill)?.fullName || ''}
                    playerId={addPlayerSkill}
                    boardLevel="Pro"
                />
            )}
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    height: '35px',
                    lineHeight: '35px',
                    width: '100%',
                    backgroundColor: 'white',
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    zIndex: 100,
                    paddingX: 1,
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
                    borderRadius: '0 0 4px 4px',
                }}
            >
                <ScoutSkillLOCProgress scoutEntityId={scoutEntityId} managerView={false} />
            </Box>
        </>
    )
}

export default SkillLOCWidgetScoutView
