import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

export const getTeamSchedule = async (
    teamId: string,
    league: Enum.League,
    season: number,
    gameTypes: Enum.GameType[]
): Promise<DTO.TeamSchedule[]> =>
    (await get<DTO.TeamSchedule[]>(`/schedule/teams/${teamId}`, { league, season, gameTypes })).data

export const useTeamSchedule = (
    teamId: string | undefined,
    league: Enum.League | undefined,
    season: number,
    gameTypes: Enum.GameType[],
    options?: Omit<UseQueryOptions<DTO.TeamSchedule[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.TeamSchedule[]> =>
    useQuery({
        queryKey: ['team-schedule', teamId, league, season, gameTypes],
        queryFn: () => getTeamSchedule(teamId!, league!, season, gameTypes), // eslint-disable-line @typescript-eslint/no-non-null-assertion
        ...options,
        enabled: options?.enabled !== false && !!teamId && !!league && !!season,
    })

const getLeagueSchedule = async (
    league: Enum.League,
    season: number,
    gameTypes: Enum.GameType[],
    startDate: string,
    endDate: string,
    teamId?: string,
    conferences?: Enum.NCAAConference[]
): Promise<DTO.LeagueSchedule[]> =>
    (
        await get<DTO.LeagueSchedule[]>(`/schedule/leagues/${league}`, {
            season,
            gameTypes,
            startDate,
            endDate,
            teamId,
            conferences,
        })
    ).data

export const useLeagueSchedule = (
    league: Enum.League | undefined,
    season: number,
    gameTypes: Enum.GameType[],
    startDate: string,
    endDate: string,
    teamId: string | undefined,
    conferences: Enum.NCAAConference[],
    states: string[] | undefined,
    options?: Omit<
        UseQueryOptions<DTO.LeagueSchedule[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.LeagueSchedule[]> =>
    useQuery({
        queryKey: ['league-schedule', startDate, endDate, teamId, conferences, league, season, gameTypes, states],

        queryFn: async () => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            let resp = await getLeagueSchedule(league!, season, gameTypes, startDate, endDate, teamId, conferences)
            // there's no need to filter states on the backend, we can easily do that here
            if (states?.length) {
                const statesSet = new Set(states)
                resp = resp.filter((game) => game.venueStateProvince && statesSet.has(game.venueStateProvince))
            }
            return resp
        },

        ...options,
        enabled: options?.enabled !== false && !!league && !!season,
    })

export const getScheduleAnalysis = async (
    teamId: string,
    timeFrame: Enum.ScheduleAnalysisTimeFrame,
    season: number,
    league: Enum.League
): Promise<DTO.ScheduleAnalysis[]> =>
    (
        await get<DTO.ScheduleAnalysis[]>(`/schedule/schedule-analysis/${teamId}`, {
            teamId,
            timeFrame,
            season,
            league,
        })
    ).data

export const useScheduleAnalysis = (
    teamId: string,
    timeFrame: Enum.ScheduleAnalysisTimeFrame,
    season: number,
    league: Enum.League,
    options?: Omit<
        UseQueryOptions<DTO.ScheduleAnalysis[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ScheduleAnalysis[]> =>
    useQuery({
        queryKey: ['schedule-analysis', teamId, timeFrame, season, league],
        queryFn: () => getScheduleAnalysis(teamId, timeFrame, season, league),
        ...options,
        enabled: options?.enabled !== false && !!teamId && !!league,
    })
