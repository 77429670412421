import React from 'react'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import { ProgressTick } from '../coverage/IntelBar'
import { diff } from '../../shared/utils/dates'
import { useGetSkillLOCProgress } from '@/lib/hooks'
import { isNumber } from '@/lib/utils/math'
import { formatDateString } from '@/lib/utils/formatters'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'

type ScoutSkillLOCProgressProps = {
    scoutEntityId: string | undefined
    managerView: boolean
}
const ScoutSkillLOCProgress = ({ scoutEntityId, managerView }: ScoutSkillLOCProgressProps): JSX.Element => {
    const { bulkSkillLOCEndDate, bulkSkillLOCStartDate } = useConstantsContext()
    const { data: progress, isLoading: isLoadingProgress } = useGetSkillLOCProgress(
        managerView ? undefined : scoutEntityId
    )
    const scoutProgress = progress?.find((p) => p.scoutId === scoutEntityId)

    const totalDays = diff(bulkSkillLOCStartDate, 'day', bulkSkillLOCEndDate)
    const daysCompleted = diff(bulkSkillLOCStartDate, 'day')
    const percentDaysComplete = daysCompleted / totalDays
    const percentComplete =
        isNumber(scoutProgress?.totalPlayers) && scoutProgress?.totalPlayers
            ? scoutProgress.completedPlayers / scoutProgress.totalPlayers
            : 0

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            {isLoadingProgress && (
                <>
                    <Skeleton variant="rounded" width={100} height={15} />
                    <Skeleton variant="rounded" width={250} height={15} />
                </>
            )}
            {!isLoadingProgress && (
                <>
                    <Typography
                        sx={
                            managerView
                                ? { color: 'text.secondary', textAlign: 'left' }
                                : { color: 'black', fontWeight: 'medium', textAlign: 'left' }
                        }
                        variant="body2"
                    >
                        {managerView
                            ? scoutProgress?.scoutName || ' '
                            : `Deadline ${formatDateString(bulkSkillLOCEndDate, 'MMM D', 'local')}`}
                    </Typography>
                    <Box sx={{ width: '65%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Box sx={{ width: '80%', mr: 1, position: 'relative' }}>
                            {percentDaysComplete && <ProgressTick value={percentDaysComplete * 100} height="16px" />}
                            <LinearProgress
                                variant="determinate"
                                value={percentComplete * 100}
                                color="primary"
                                sx={{
                                    height: '8px',
                                    width: '100%',
                                }}
                            />
                        </Box>
                        <Box sx={{ width: '10%' }}>
                            <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'right' }}>
                                {Math.round(percentComplete * 100)}%
                            </Typography>
                        </Box>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default ScoutSkillLOCProgress
