import React, { useState } from 'react'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import SkillLOCWidgetScoutView from './SkillLOCWidgetScoutView'
import SkillLOCWidgetManagerView from './SkillLOCWidgetManagerView'

type BulkSkillLOCWidgetProps = {
    hasInputPermissions: boolean
    hasManagementPermissions: boolean
    scoutEntityId: string | undefined
}
const BulkSkillLOCWidget = ({
    hasInputPermissions,
    hasManagementPermissions,
    scoutEntityId,
}: BulkSkillLOCWidgetProps): JSX.Element => {
    const managerOnly = hasManagementPermissions && !hasInputPermissions
    const inputAndManager = hasInputPermissions && hasManagementPermissions
    const [view, setView] = useState<'scout' | 'manager'>(managerOnly ? 'manager' : 'scout')
    return (
        <Box>
            {inputAndManager && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: 1 }}>
                    <FormControl size="small">
                        <InputLabel id="showing-label">View</InputLabel>
                        <Select
                            sx={{
                                textAlign: 'left',
                                height: '25px',
                                backgroundColor: 'common.white',
                                minWidth: '105px',
                            }}
                            value={view}
                            onChange={(e) => {
                                setView(e.target.value as 'scout' | 'manager')
                            }}
                            label="View"
                            name="view"
                        >
                            <MenuItem sx={{ minHeight: '20px' }} value="scout">
                                Scout
                            </MenuItem>
                            <MenuItem sx={{ minHeight: '20px' }} value="manager">
                                Manager
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            )}
            {view === 'scout' && <SkillLOCWidgetScoutView scoutEntityId={scoutEntityId} />}
            {view === 'manager' && <SkillLOCWidgetManagerView />}
        </Box>
    )
}

export default BulkSkillLOCWidget
