'use client'

import type TableConfig from '@/lib/types/tableConfigTypes'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import { ascending } from 'd3-array'
import dayjs from 'dayjs'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useSession } from 'next-auth/react'
import Table from '../Table'
import TeamLogoAndName from '../TeamLogoAndName'
import CalendarEventDialog from '../scout-assignments/CalendarEventDialog'
import SelectLeague from '../form/SelectLeague'
import SelectNCAAConference from '../form/SelectNCAAConference'
import ScheduleResult from '../schedule/ScheduleResult'
import { getLeagueGameTypes } from '../schedule/SelectGameType'
import { useLeagueSchedule } from '@/lib/hooks/useSchedule'
import { dateAdd, formatDateString } from '@/lib/utils/formatters'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'
import { formatNumber, isNumber } from '@/lib/utils/math'
import { useQueryScoutAssignments } from '@/lib/hooks'
import { powerConferences } from '@/lib/utils/teams'

const widgetTableConfig = (
    league: Enum.League,
    setSelectedGameId: (selectedGameId: string | undefined) => void,
    showWinProb: boolean
): TableConfig<DTO.LeagueSchedule> => {
    const fields: TableConfig<DTO.LeagueSchedule>['fields'] = [
        {
            key: 'matchup',
            label: 'Matchup',
            select: (g) => (
                <Stack alignItems="center" direction="row" spacing={0}>
                    <Box sx={{ width: league === 'College' ? '65px' : '60px' }}>
                        <TeamLogoAndName
                            teamName={g.awayTeamAbbr}
                            teamAbbr={g.awayTeamAbbr}
                            teamLogo={g.awayTeamLogo}
                            teamSlug={g.awayTeamSlug}
                            bold={!g.isHomeTeamWin && g.gameStatus === 'FINISHED'}
                            teamLogoHeight={20}
                            teamLogoWidth={20}
                            href="schedule"
                            gap={0}
                            abbreviate
                        />
                    </Box>
                    <Typography sx={{ marginLeft: 0.75 }}>@</Typography>
                    <TeamLogoAndName
                        teamName={g.homeTeamAbbr}
                        teamAbbr={g.homeTeamAbbr}
                        teamLogo={g.homeTeamLogo}
                        teamSlug={g.homeTeamSlug}
                        bold={!!g.isHomeTeamWin && g.gameStatus === 'FINISHED'}
                        teamLogoHeight={20}
                        teamLogoWidth={20}
                        href="schedule"
                        gap={0}
                        abbreviate
                    />
                </Stack>
            ),
            skeletonStyle: { xs: 180 },
        },
        {
            key: 'game',
            label: 'Time/Result',
            select: (g) => {
                if (g.gameStatus === 'FINISHED') {
                    return (
                        g.awayTeamAbbr &&
                        g.awayTeamScore &&
                        g.homeTeamAbbr &&
                        g.homeTeamScore &&
                        g.isHomeTeamWin !== null && (
                            <ScheduleResult
                                awayTeamAbbr={g.awayTeamAbbr}
                                awayTeamScore={g.awayTeamScore}
                                homeTeamAbbr={g.homeTeamAbbr}
                                homeTeamScore={g.homeTeamScore}
                                isHomeTeamWin={g.isHomeTeamWin}
                                gameStatusText={g.gameStatusText}
                                gameStatus={g.gameStatus}
                                gameTimeUTC={g.gameTimeUTC}
                                gameId={g.gameId}
                                setSelectedGameId={setSelectedGameId}
                            />
                        )
                    )
                }
                return formatDateString(g.gameTimeUTC, 'h:mm A', 'eastern')
            },
            numeric: true,
            skeletonStyle: { xs: 60 },
        },
    ]

    if (league === 'NBA') {
        if (showWinProb) {
            fields.push({
                key: 'win',
                label: 'Win Prob',
                select: (g) =>
                    !g.homeWinProb ? (
                        <Box />
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
                            <Box sx={{ fontWeight: 'bold' }}>
                                {isNumber(g.homeWinProb) && g.homeWinProb > 0.5 ? g.homeTeamAbbr : g.awayTeamAbbr}
                            </Box>
                            <Box>
                                {formatNumber(
                                    isNumber(g.homeWinProb) && g.homeWinProb > 0.5
                                        ? g.homeWinProb
                                        : 1 - Number(g.homeWinProb),
                                    1,
                                    'percent'
                                )}
                            </Box>
                        </Box>
                    ),
                skeletonStyle: { xs: 50 },
            })
        } else {
            fields.push({
                key: 'tv',
                label: 'TV',
                select: (g) => g.nationalBroadcastName,
                skeletonStyle: { xs: 100 },
            })
        }
    }

    return {
        fields,
        loadingSkeleton: {
            height: 300,
            numOfRows: 15,
        },
    }
}

const nykAbbr = ['NYK', 'WES']

const LeagueScheduleWidget = (): JSX.Element => {
    const { scheduleYear } = useConstantsContext()

    const [selectedLeague, setSelectedLeague] = useState<Enum.League>(dayjs().month() === 6 ? 'Summer' : 'NBA')
    const [selectedNCAAConference, setSelectedNCAAConference] = useState<Enum.NCAAConference | 'POWER' | undefined>(
        undefined
    )
    const [selectedOffset, setSelectedOffset] = useState<number>(dayjs().hour() > 12 ? 0 : -1)
    const conference =
        selectedLeague !== 'College' || !selectedNCAAConference
            ? []
            : selectedNCAAConference === 'POWER'
            ? powerConferences
            : [selectedNCAAConference]
    const date = formatDateString(dateAdd(undefined, 'day', selectedOffset), 'YYYY-MM-DD', 'eastern')
    const afternoon = selectedOffset === 0

    const { data: session } = useSession()
    const showWinProb = session?.roles.contentPermissions['predictive-standings'] && afternoon

    const { data: schedule, isLoading: isLoadingSchedule } = useLeagueSchedule(
        selectedLeague,
        selectedLeague === 'Summer' ? scheduleYear + 1 : scheduleYear,
        getLeagueGameTypes(selectedLeague).map((type) => type.value),
        date,
        date,
        undefined,
        conference,
        undefined
    )
    const [selectedGameId, setSelectedGameId] = useState<string | undefined>(undefined)
    const { data: selectedGames } = useQueryScoutAssignments(
        {
            gameIds: selectedGameId ? [selectedGameId] : undefined,
            viewing: 'games',
        },
        { enabled: !!selectedGameId }
    )
    const selectedGame = selectedGames?.length ? selectedGames[0] : null
    const sortedSchedule: DTO.LeagueSchedule[] =
        schedule?.sort(
            (a, b) =>
                ascending(
                    nykAbbr.includes(a.homeTeamAbbr || '') || nykAbbr.includes(a.awayTeamAbbr || '') ? 0 : 1,
                    nykAbbr.includes(b.homeTeamAbbr || '') || nykAbbr.includes(b.awayTeamAbbr || '') ? 0 : 1
                ) ||
                ascending(
                    a.gameTimeUTC ? new Date(a.gameTimeUTC).getTime() : 0,
                    b.gameTimeUTC ? new Date(b.gameTimeUTC).getTime() : 0
                ) ||
                ascending(a.awayTeamAbbr || '', b.awayTeamAbbr || '')
        ) || []

    return (
        <Box>
            <Box
                sx={{
                    padding: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 1,
                        width: '100%',
                    }}
                >
                    <Box sx={{ maxWidth: '120px' }}>
                        <FormControl fullWidth size="small">
                            <Select
                                name="Day"
                                onChange={(e) => setSelectedOffset(Number(e.target.value))}
                                value={selectedOffset}
                                multiple={false}
                                MenuProps={{ style: { maxHeight: 400 } }}
                                sx={{
                                    height: 30,
                                }}
                            >
                                {[0, -1].map((l) => (
                                    <MenuItem key={l} value={l}>
                                        {l === 0 ? 'Today' : 'Yesterday'}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box sx={{ maxWidth: '200px' }}>
                        <SelectLeague
                            onChange={(e) => {
                                const league = e.target.value as Enum.League
                                setSelectedLeague(league)
                                if (league !== 'College') {
                                    setSelectedNCAAConference(undefined)
                                } else {
                                    setSelectedNCAAConference('POWER')
                                }
                            }}
                            showAll={false}
                            multiple={false}
                            value={selectedLeague}
                            leagueOptions={['NBA', 'G-League', 'College', 'Summer']}
                            height="30px"
                        />
                    </Box>
                    {selectedLeague === 'College' && selectedNCAAConference && (
                        <Box sx={{ width: '125px' }}>
                            <SelectNCAAConference
                                onChange={(e) => {
                                    setSelectedNCAAConference(e.target.value as Enum.NCAAConference)
                                }}
                                height={30}
                                value={selectedNCAAConference}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
            <Box minHeight="605px">
                <Table<DTO.LeagueSchedule>
                    config={widgetTableConfig(selectedLeague, setSelectedGameId, !!showWinProb)}
                    rows={sortedSchedule}
                    isLoading={isLoadingSchedule}
                    getRowKey={(p) => p.gameId}
                    hideSorting
                    hover={false}
                    stickyHeader
                    noResultsMessage="No games scheduled"
                    formatTableContainer={{
                        // @ts-expect-error valid css
                        td: {
                            borderBottom: sortedSchedule.length ? undefined : '0px solid black',
                        },
                    }}
                    hideSortIcon
                />
            </Box>
            <CalendarEventDialog
                open={!!selectedGameId}
                setOpen={() => setSelectedGameId(undefined)}
                event={selectedGame?.extendedProps}
                filterParams={{ viewing: 'games' }}
                widget
                showControls={false}
            />
        </Box>
    )
}

export default LeagueScheduleWidget
