import type TableConfig from '@/lib/types/tableConfigTypes'
import type { TableOrder, OrderByValue } from '../Table/sorting'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'
import Table from '../Table'
import TeamLogo from '../stats/StatsTeamLogo'
import Link from '../Link'
import TeamLogoAndName from '../TeamLogoAndName'
import { getOrderByValue } from '../Table/sorting'
import { formatNumber } from '@/lib/utils/math'
import { horizontalStickyStyles } from '@/lib/utils/sticky'
import { useBreakPoints } from '@/lib/hooks'
import ConditionalWrapper from '@/components/wrappers/ConditionalWrapper'

type CurrentStandingsTableProps = {
    leagueName: Enum.League
    isLoading: boolean
    standings: DTO.Standing[] | undefined
    seasonType: Enum.SeasonType
    isWidget?: boolean
    group?: Enum.ISTGroup
}

export const getBorderWeight = (
    teamId: string | undefined | null,
    index: number,
    tableOrder: TableOrder<DTO.Standing>[] | undefined,
    league: Enum.League,
    isWidget: boolean | undefined
): '3px solid' | '1px solid' | '1px dashed' | undefined => {
    if (tableOrder?.[0].orderBy === 'conference' && tableOrder[0].order === 'asc') {
        if (index === (league === 'NBA' ? 14 : 15) && teamId && !isWidget) {
            return '3px solid'
        }
        if (index === (league === 'NBA' ? 9 : 5) || (index === (league === 'NBA' ? 24 : 10) && teamId)) {
            return '1px solid'
        }
        if (index === (league === 'NBA' ? 5 : 1) || (index === (league === 'NBA' ? 20 : 10) && teamId)) {
            return '1px dashed'
        }
    }

    if (tableOrder?.[0].orderBy === 'conference' && tableOrder[0].order === 'desc') {
        if (index === 14 && teamId && !isWidget) {
            return '3px solid'
        }
        if (index === (league === 'NBA' ? 8 : 4) || (index === (league === 'NBA' ? 23 : 9) && teamId)) {
            return '1px dashed'
        }
        if (index === (league === 'NBA' ? 4 : 13) || (index === (league === 'NBA' ? 19 : 10) && teamId)) {
            return '1px solid'
        }
    }

    return undefined
}

const tableConfig = (
    leagueName: Enum.League,
    isMobile: boolean,
    seasonType: Enum.SeasonType,
    isWidget: boolean | undefined
): TableConfig<DTO.Standing> => {
    let fields: TableConfig<DTO.Standing>['fields'] = []

    if (seasonType.includes('REGULAR') || seasonType.includes('SHOWCASE')) {
        fields = [
            {
                key: 'seed',
                label: '',
                select: (d, idx, { tableOrder }) => {
                    let rankValue: OrderByValue = idx + 1
                    if (tableOrder?.length === 1 && tableOrder[0].orderBy !== 'conference') {
                        rankValue = getOrderByValue(d, tableOrder[0].orderBy)
                    }
                    return (
                        <Box display="flex" alignItems="center" gap={1}>
                            <Typography
                                sx={{
                                    fontSize: 16,
                                    minWidth: 20,
                                    fontWeight: 'medium',
                                    textAlign: 'right',
                                }}
                            >
                                {tableOrder?.some((x) => x.orderBy === 'conference') ? (
                                    <>
                                        {d.seed}{' '}
                                        {d.clinchStatus && (
                                            <Typography component="span" sx={{ fontSize: 12 }}>
                                                &#8211;{d.clinchStatus}
                                            </Typography>
                                        )}
                                    </>
                                ) : (
                                    (rankValue as number | undefined)
                                )}
                            </Typography>
                            <TeamLogoAndName
                                teamName={d.teamName}
                                teamAbbr={d.teamAbbr}
                                teamLogo={d.teamLogo}
                                teamSlug={d.teamSlug}
                                abbreviate={isWidget}
                            />
                        </Box>
                    )
                },
                skeletonStyle: { xs: 80 },
                sortColumn: 'seed',
                headCellFormat: { ...horizontalStickyStyles('th'), borderRight: 'none' },
                format: (x, { tableOrder }, i) => ({
                    whiteSpace: 'nowrap',
                    ...horizontalStickyStyles('td'),
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                    width: isWidget ? 172 : undefined,
                    maxWidth: isWidget ? 172 : undefined,
                }),
            },
        ]
        if (!isWidget)
            fields.push({
                label: 'Conf.',
                key: 'conference',
                select: (x) => x.conference,
                skeletonStyle: { xs: 30 },
                sortColumn:
                    leagueName === 'NBA' || seasonType === 'GLG_REGULAR_SEASON'
                        ? ['conference', 'seed', 'gamesBack']
                        : 'seed',
                format: (x, { tableOrder }, i) => ({
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                }),
            })
        fields.push(
            {
                label: 'W',
                key: 'wins',
                sortColumn: 'winsRank',
                numeric: true,
                select: (d) => d.wins,
                skeletonStyle: { xs: 30 },
                headCellFormat: { width: isWidget ? 20 : undefined },
                format: (x, { tableOrder }, i) => ({
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                }),
            },
            {
                label: 'L',
                key: 'losses',
                sortColumn: 'lossesRank',
                numeric: true,
                select: (d) => d.losses,
                skeletonStyle: { xs: 30 },
                format: (x, { tableOrder }, i) => ({
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                }),
            }
        )
        if (!isWidget)
            fields.push({
                label: 'Pct.',
                key: 'winPct',
                sortColumn: 'winPctRank',
                select: (d) => formatNumber(d.winPct, 1, 'percent'),
                numeric: true,
                skeletonStyle: { xs: 30 },
                format: (x, { tableOrder }, i) => ({
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                }),
            })
        fields.push({
            label: 'GB',
            key: 'gamesBack',
            sortColumn: 'gamesBackRank',
            select: (d) => (d.gamesBack === 0 ? <span>&#8211;</span> : d.gamesBack),
            numeric: true,
            skeletonStyle: { xs: 30 },
            format: (x, { tableOrder }, i) => ({
                borderBottom:
                    leagueName === 'NBA' ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget) : undefined,
            }),
        })
        if (!isWidget)
            fields.push(
                {
                    label: 'Home',
                    key: 'homeWins',
                    sortColumn: 'homeWinPctRank',
                    select: (d) =>
                        (d.homeWins || d.homeWins === 0) && (d.homeLosses || d.homeLosses === 0) ? (
                            <Typography sx={{ fontSize: 14 }}>
                                {d.homeWins}
                                <span>&#8211;</span>
                                {d.homeLosses}
                            </Typography>
                        ) : null,
                    numeric: true,
                    skeletonStyle: { xs: 30 },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                        whiteSpace: 'nowrap',
                    }),
                },
                {
                    label: 'Away',
                    key: 'awayWins',
                    sortColumn: 'awayWinPctRank',
                    select: (d) =>
                        (d.awayWins || d.awayWins === 0) && (d.awayLosses || d.awayLosses === 0) ? (
                            <Typography sx={{ fontSize: 14 }}>
                                {d.awayWins}
                                <span>&#8211;</span>
                                {d.awayLosses}
                            </Typography>
                        ) : null,
                    numeric: true,
                    skeletonStyle: { xs: 30 },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                        whiteSpace: 'nowrap',
                    }),
                },
                {
                    label: 'Div.',
                    key: 'divisionWins',
                    sortColumn: 'divisionWinPctRank',
                    select: (d) =>
                        (d.divisionWins || d.divisionWins === 0) && (d.divisionLosses || d.divisionLosses === 0) ? (
                            <Typography sx={{ fontSize: 14 }}>
                                {d.divisionWins}
                                <span>&#8211;</span>
                                {d.divisionLosses}
                            </Typography>
                        ) : null,
                    numeric: true,
                    skeletonStyle: { xs: 30 },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                        whiteSpace: 'nowrap',
                    }),
                },
                {
                    label: 'Conf.',
                    key: 'conferenceWins',
                    sortColumn: 'conferenceWinPctRank',
                    select: (d) =>
                        (d.conferenceWins || d.conferenceWins === 0) &&
                        (d.conferenceLosses || d.conferenceLosses === 0) ? (
                            <Typography sx={{ fontSize: 14 }}>
                                {d.conferenceWins}
                                <span>&#8211;</span>
                                {d.conferenceLosses}
                            </Typography>
                        ) : null,
                    numeric: true,
                    skeletonStyle: { xs: 30 },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                        whiteSpace: 'nowrap',
                    }),
                },
                {
                    label: 'PPG',
                    key: 'ppg',
                    sortColumn: 'ppgRank',
                    numeric: true,
                    select: (d) => formatNumber(d.ppg, 1, 'decimal'),
                    skeletonStyle: { xs: 30 },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                    }),
                },
                {
                    label: 'Opp. PPG',
                    key: 'oppPPG',
                    sortColumn: 'oppPPGRank',
                    numeric: true,
                    select: (d) => formatNumber(d.oppPPG, 1, 'decimal'),
                    skeletonStyle: { xs: 30 },
                    headCellFormat: { whiteSpace: 'nowrap' },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                    }),
                },
                {
                    label: 'Diff',
                    key: 'netPPG',
                    sortColumn: 'netPPGRank',
                    numeric: true,
                    select: (d) => (
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: d.netPPG === 0 ? undefined : d.netPPG && d.netPPG > 0 ? '#388e3c' : '#f44336',
                            }}
                        >
                            {d.netPPG && d.netPPG > 0 ? '+' : ''}
                            {formatNumber(d.netPPG, 1, 'decimal')}
                        </Typography>
                    ),
                    skeletonStyle: { xs: 30 },
                    format: (x, { tableOrder }, i) => ({
                        borderBottom:
                            leagueName === 'NBA'
                                ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                                : undefined,
                    }),
                }
            )
        fields.push(
            {
                label: 'Streak',
                key: 'streak',
                sortColumn: 'streakRank',
                select: (d) => {
                    if (d.streak) {
                        if (d.streak < 0) {
                            return `L${Math.abs(d.streak)}`
                        }
                        if (d.streak > 0) {
                            return `W${Math.abs(d.streak)}`
                        }
                    }
                    return null
                },
                skeletonStyle: { xs: 30 },
                numeric: true,
                format: (x, { tableOrder }, i) => ({
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                }),
            },
            {
                label: 'L10',
                key: 'lastTenRecord',
                sortColumn: 'last10WinsRank',
                select: (d) =>
                    (d.last10Wins || d.last10Wins === 0) && (d.last10Losses || d.last10Losses === 0) ? (
                        <Typography sx={{ fontSize: 14 }}>
                            {d.last10Wins}
                            <span>&#8211;</span>
                            {d.last10Losses}
                        </Typography>
                    ) : null,
                skeletonStyle: { xs: 30 },
                numeric: true,
                format: (x, { tableOrder }, i) => ({
                    borderBottom:
                        leagueName === 'NBA'
                            ? getBorderWeight(x.teamId, i, tableOrder, leagueName, isWidget)
                            : undefined,
                    whiteSpace: 'nowrap',
                }),
            }
        )

        if (leagueName === 'G-League') {
            fields.push({
                label: 'Affiliate',
                key: 'affiliate',
                select: (x) => <TeamLogo height={33} width={33} url={x.affiliateLogo} name={null} />,
                skeletonStyle: { xs: 40 },
            })
        }
    } else {
        fields = [
            {
                key: 'seed',
                label: '',
                select: (d) => (
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography sx={{ fontSize: 16, minWidth: 40, fontWeight: 'medium' }}>
                            {d.istGroupRank}{' '}
                            {d.istClinchStatus && (
                                <Typography component="span" sx={{ fontSize: 12 }}>
                                    &#8211;{d.istClinchStatus}
                                </Typography>
                            )}
                        </Typography>
                        <Box gap={1} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                            <TeamLogo url={d.teamLogo} name={null} />
                            {d.teamSlug ? (
                                <Link target="_blank" href={`/teams/${d.teamSlug}/feed`}>
                                    {!isMobile ? d.teamName : d.teamAbbr}
                                </Link>
                            ) : (
                                d.teamName
                            )}
                        </Box>
                    </Box>
                ),
                skeletonStyle: { xs: 80 },
                sortColumn: 'seed',
                headCellFormat: { ...horizontalStickyStyles('th'), borderRight: 'none' },
                format: {
                    whiteSpace: 'nowrap',
                    ...horizontalStickyStyles('td'),
                },
            },
            {
                label: 'W',
                key: 'wins',
                numeric: true,
                select: (d) => d.istWins,
                skeletonStyle: { xs: 30 },
                headCellFormat: { width: isWidget ? 20 : undefined },
            },
            {
                label: 'L',
                key: 'losses',
                numeric: true,
                select: (d) => d.istLosses,
                skeletonStyle: { xs: 30 },
            },
            {
                label: 'Pct.',
                key: 'istWinPct',
                numeric: true,
                select: (d) => formatNumber(d.istWinPct, 1, 'percent'),
                skeletonStyle: { xs: 30 },
            },
            {
                label: 'Grp. GB',
                key: 'grpGB',
                numeric: true,
                headCellFormat: { whiteSpace: 'nowrap' },
                select: (d) => formatNumber(d.istGroupGb, 1, 'decimal'),
                skeletonStyle: { xs: 30 },
            },
            {
                label: 'Points',
                key: 'istPts',
                numeric: true,
                select: (d) => d.istPts,
                skeletonStyle: { xs: 30 },
            },
            {
                label: 'Opp. Points',
                key: 'istOppPts',
                numeric: true,
                headCellFormat: { whiteSpace: 'nowrap' },
                select: (d) => d.istOppPts,
                skeletonStyle: { xs: 30 },
            },
            {
                label: 'Diff',
                key: 'istNetPts',
                numeric: true,
                select: (d) => (
                    <Typography
                        sx={{
                            fontSize: 14,
                            color:
                                d.istNetPts === 0 ? undefined : d.istNetPts && d.istNetPts > 0 ? '#388e3c' : '#f44336',
                        }}
                    >
                        {d.istNetPts && d.istNetPts > 0 && '+'}
                        {formatNumber(d.istNetPts, 0, 'decimal')}
                    </Typography>
                ),
                skeletonStyle: { xs: 30 },
            },
        ]
    }

    return {
        fields,
        loadingSkeleton: {
            numOfRows: seasonType.includes('REGULAR') || seasonType.includes('SHOWCASE') ? 30 : 5,
            height: seasonType.includes('REGULAR') || seasonType.includes('SHOWCASE') ? 600 : 100,
        },
        initialSortBy: seasonType.includes('REGULAR')
            ? ['conference', 'seed', 'gamesBack']
            : seasonType === 'GLG_SHOWCASE'
            ? 'seed'
            : ['istGroupRank', 'istGroupGb'],
        initialSortOrder: 'asc',
    }
}

const PaperWrapper = ({ children }: { children: JSX.Element }): JSX.Element => (
    <Paper variant="outlined">{children}</Paper>
)

const CurrentStandingsTable = ({
    leagueName,
    isLoading,
    standings,
    isWidget,
    seasonType,
    group,
}: CurrentStandingsTableProps): JSX.Element => {
    const { isMobile } = useBreakPoints()
    const currentStandingsTableConfig = useMemo(
        () => tableConfig(leagueName, isMobile, seasonType, isWidget),
        [leagueName, isMobile, seasonType, isWidget]
    )

    return (
        <ConditionalWrapper condition={!isWidget} wrapper={(children) => PaperWrapper({ children })}>
            <>
                {group && (
                    <Box paddingX="16px" paddingY="8px">
                        <Typography variant="h6">{`${group}`}</Typography>
                    </Box>
                )}
                <Table<DTO.Standing>
                    config={currentStandingsTableConfig}
                    rows={standings?.length ? standings : []}
                    isLoading={isLoading}
                    getRowKey={(p) => p.teamId}
                    noResultsMessage="Standings Not Available"
                    hideSorting={isWidget}
                    hover={!isWidget}
                    hideSortIcon
                />
            </>
        </ConditionalWrapper>
    )
}

export default CurrentStandingsTable
