'use client'

import type { HydrationBoundaryProps } from '@tanstack/react-query'
import React from 'react'
import { HydrationBoundary as RQHydrate } from '@tanstack/react-query'

function Hydrate(props: HydrationBoundaryProps): JSX.Element {
    return <RQHydrate {...props} />
}

export default Hydrate
