import type { LinearProgressProps } from '@mui/material/LinearProgress'
import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

type ProgressTickProps = { value: number; height: string }
export const ProgressTick = ({ value, height }: ProgressTickProps): JSX.Element => (
    <Box
        sx={{
            position: 'absolute',
            left: `${value}%`,
            transform: 'translateX(-50%)',
            top: '-4px',
            bottom: 0,
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderColor: 'grey.500',
            zIndex: 1,
            height,
        }}
    />
)

type CoverageBarProps = LinearProgressProps & {
    value: number
    valueLabel: string | null
    tickValue?: number
    color: string
    height?: number
    tickHeight?: string
    label?: string
    showInitials?: boolean
    initials?: string
}
const CoverageBar = ({
    value,
    valueLabel,
    tickValue,
    color,
    label,
    height = 8,
    tickHeight = '16px',
    showInitials = false,
    initials,
    ...props
}: CoverageBarProps): JSX.Element => (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {label && (
            <Box>
                <Typography variant="body2" color="text.primary" fontWeight="medium">
                    {label}
                </Typography>
            </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {showInitials && (
                <Box sx={{ minWidth: 25 }}>
                    {initials && (
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {initials}
                        </Typography>
                    )}
                </Box>
            )}
            <Box sx={{ width: '100%', mr: 1, position: 'relative' }}>
                {tickValue && <ProgressTick value={tickValue} height={tickHeight} />}
                <LinearProgress
                    variant="determinate"
                    value={value}
                    color={color}
                    {...props}
                    sx={{
                        height,
                    }}
                />
            </Box>
            <Box sx={{ minWidth: 25 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: 'right' }}>
                    {valueLabel}
                </Typography>
            </Box>
        </Box>
    </Box>
)

export default CoverageBar
