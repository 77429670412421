import React from 'react'
import Box from '@mui/material/Box'
import { ascending } from 'd3'
import { Skeleton } from '@mui/material'
import ScoutSkillLOCProgress from './ScoutSkillLOCProgress'
import { useQueryScouts } from '@/lib/hooks'
import { getSortingLastName } from '@/lib/utils/formatters'

const rowStyles = { paddingX: 1, paddingY: 1, borderTop: '1px solid', borderColor: 'divider' }

const SkillLOCWidgetManagerView = (): JSX.Element => {
    const { data: scouts, isLoading: isLoadingScouts } = useQueryScouts('Pro')
    if (isLoadingScouts)
        return (
            <>
                {Array.from({ length: 5 }, (_, i) => i).map((d) => (
                    <Box
                        key={d}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            ...rowStyles,
                        }}
                    >
                        <Skeleton variant="rounded" width={100} height={15} />
                        <Skeleton variant="rounded" width={250} height={15} />
                    </Box>
                ))}
            </>
        )

    return (
        <Box sx={{ width: '100%' }}>
            {scouts
                ?.filter((d) => d.userId !== 34) // Don't show Andrew Hill
                .sort(
                    (a, b) =>
                        ascending(getSortingLastName(a.name) || '', getSortingLastName(b.name) || '') ||
                        ascending(a.name, b.name)
                )
                ?.map((s) => (
                    <Box key={s.entityId} sx={rowStyles}>
                        <ScoutSkillLOCProgress scoutEntityId={s.entityId} managerView />
                    </Box>
                ))}
        </Box>
    )
}

export default SkillLOCWidgetManagerView
